import React, { useEffect, useState } from 'react'
import { ActionIcon, Badge, Button, Card, Chip, Collapse, Divider, Group, HoverCard, Menu, Modal, Radio, Select, Stack, Table, Text, Textarea, TextInput } from '@mantine/core';
import { ICustomer, TInspection, TInspectionType, TTrustee } from '../data/ICustomer';
import { useForm } from '@mantine/form';
import { BsCheck, BsGripVertical, BsInfoCircle, BsPencil, BsQuestion, BsThreeDotsVertical, BsTrash, BsX } from 'react-icons/bs';

const defaultInspectionRows: TInspection[] = [
    { type: 'EQUIPMENT', info: 'BLOCO 1 - BAP 01 SEGUNDA LINHA' },
    { type: 'EQUIPMENT', info: 'BLOCO 1 - BAP 02' },
    { type: 'EQUIPMENT', info: 'BLOCO 2 - BAP 03' },
    { type: 'EQUIPMENT', info: 'BLOCO 2 - BAP 04' },
    { type: 'EQUIPMENT', info: 'Horário de atendimento: 234132 12341 12343 adsfasdf' },
];

const defaultInspectionItem: TInspection = { type: 'EQUIPMENT', info: '' }

type TCustomerTrusteeFieldProps = {
    data: TTrustee,
}

type TCustomerEditorProps = {
    customer: ICustomer;
    opened: boolean;
    setOpened: (isOpen: boolean) => void;
}

const CustomerEditor = ({ customer, opened = false, setOpened }: TCustomerEditorProps) => {
    const title = (customer.id === -1 ? 'Incluir novo' : 'Editar') + ' cliente';

    const [inspectionRows, setInspectionRows] = useState<TInspection[]>(defaultInspectionRows);
    const [inspectionItemEditorValues, setInspectionItemEditorValues] = useState<TInspection>(defaultInspectionItem);
    const [inspectionItemIndexToEdit, setInspectionItemIndexToEdit] = useState<number>(-1);

    const editInspectionItem = (item: TInspection = defaultInspectionItem, index: number = -1) => {
        setInspectionItemIndexToEdit(index);
        setInspectionItemEditorValues(item);
        setInspectionItemEditorOpened(true);
    }

    const deleteInspectionItem = (id: number) => {
        setInspectionItemEditorValues(defaultInspectionItem);
        setInspectionRows(inspectionRows.filter((row, index) => index !== id));
    }

    const [inspectionItemEditorOpened, setInspectionItemEditorOpened] = useState(false);

    const cancelInspectionItemEditor = () => {
        setInspectionItemEditorOpened(false);
    }

    const handleOnClickIncludeEquipmentCodeButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log(event.currentTarget.dataset.tag)
    }

    const saveInspectionItemEditor = () => {
        if (inspectionItemEditorValues.info.trim().length === 0) return;
        if (inspectionItemIndexToEdit < 0)
            setInspectionRows(inspectionRows => ([
                ...inspectionRows,
                inspectionItemEditorValues
            ]));
        else
            setInspectionRows(
                inspectionRows.map(
                    (item, index) =>
                        (index === inspectionItemIndexToEdit) ?
                            { ...inspectionItemEditorValues } : item
                )
            )
        setInspectionItemEditorOpened(false);
    }

    // useEffect(()=>{
    //     console.log(inspectionItemEditorValues)
    // }, [inspectionItemEditorValues])

    const form = useForm({
        initialValues: {
            id: customer.id,
            name: customer.name,
            address: customer.address,
            contract: customer.contract,
            trustee: customer.trustee,
            inspection: customer.inspection
        }
    })

    return (
        <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            title={
                <Text
                    size='lg'
                    weight='bold'
                    sx={theme => ({
                        fontFamily: theme.headings.fontFamily
                    })}
                >
                    {title}
                </Text>
            }
            overflow='outside'
            overlayOpacity={0.5}
            overlayBlur={3}
            closeOnClickOutside={false}
            size='lg'
        >
            <form
                onSubmit={form.onSubmit(values => console.log(values))}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                }}
            >
                <TextInput
                    required
                    label='Nome'
                    placeholder='Nome do cliente'
                    {...form.getInputProps('name')}
                />
                <Textarea
                    required
                    label='Endereço'
                    placeholder='Endereço do cliente...'
                    minRows={2}
                    {...form.getInputProps('address')}
                />
                <Select
                    required
                    label='Contrato tipo'
                    placeholder='Tipo de contrato'
                    data={['SIMPLES', 'GLOBAL']}
                />
                <Stack spacing={2}>
                    <Text size='sm'>Administradores</Text>
                    <Group align='stretch' grow noWrap={false}>
                        <Card withBorder pt='0.5rem'>
                            <TextInput
                                required
                                label='Cargo (1º)'
                                placeholder='Síndico, Zelador, etc.'
                                size='xs'
                            />
                            <Textarea
                                required
                                label='Informações de contato (1º)'
                                placeholder='Informações para contato...'
                                size='xs'
                            />
                        </Card>
                        <Card withBorder pt='0.5rem'>
                            <TextInput
                                label='Cargo (2º)'
                                placeholder='Síndico, Zelador, etc.'
                                size='xs'
                            />
                            <Textarea
                                label='Informações de contato (2º)'
                                placeholder='Informações para contato...'
                                size='xs'
                            />
                        </Card>
                    </Group>
                </Stack>
                <Text size='md'>Lista de inspeção</Text>
                <Stack>
                    <Table striped>
                        <tbody>
                            {inspectionRows.map((row, index) => (
                                <tr key={index}>
                                    <td>
                                        <BsGripVertical size={16} style={{ opacity: 0.5 }} />
                                    </td>
                                    <td>
                                        {row.type === 'NOTE' ? 'ANOTAÇÃO' : 'EQUIPAMENTO'}
                                    </td>
                                    <td>
                                        {row.info}
                                    </td>
                                    <td>
                                        <Menu shadow='md' width={200}>
                                            <Menu.Target>
                                                <ActionIcon radius='xl' variant='subtle'>
                                                    <BsThreeDotsVertical />
                                                </ActionIcon>
                                            </Menu.Target>
                                            <Menu.Dropdown>
                                                <Menu.Item
                                                    icon={<BsPencil />}
                                                    onClick={() => editInspectionItem(row, index)}
                                                >
                                                    Editar
                                                </Menu.Item>
                                                <Menu.Item
                                                    icon={<BsTrash />}
                                                    color='red'
                                                    onClick={() => deleteInspectionItem(index)}
                                                >
                                                    Deletar
                                                </Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Collapse in={!inspectionItemEditorOpened}>
                        <Button
                            variant='outline'
                            onClick={() => editInspectionItem()}
                            fullWidth
                        >
                            Incluir item na lista de inspeção
                        </Button>
                    </Collapse>
                    <Collapse in={inspectionItemEditorOpened}>
                        <Card withBorder shadow='md'>
                            <Stack>
                                <Group position='apart' align='center'>
                                    <Text>Editar item da lista</Text>
                                    {(inspectionItemEditorValues.type !== 'NOTE') &&
                                        <HoverCard shadow='xl' withinPortal>
                                            <HoverCard.Target>
                                                <Text
                                                    sx={{
                                                        display: 'flex',
                                                        gap: '0.5rem',
                                                        alignItems: 'center',
                                                        opacity: 0.7
                                                    }}
                                                >
                                                    Códigos de equipamento
                                                    <BsInfoCircle size={18} />
                                                </Text>
                                            </HoverCard.Target>
                                            <HoverCard.Dropdown>
                                                <Table striped verticalSpacing={2} fontSize='xs'>
                                                    <caption>Inserir código de Equipamento</caption>
                                                    <tbody>
                                                        <tr><td><Button variant='outline' size='xs' radius='xl' compact onClick={handleOnClickIncludeEquipmentCodeButton} data-tag='BAC'>BAC</Button></td><td>BOMBA DO AR CONDICIONADO</td></tr>
                                                        <tr><td><Button variant='outline' size='xs' radius='xl' compact onClick={handleOnClickIncludeEquipmentCodeButton} data-tag='BAP'>BAP</Button></td><td>BOMBA DE ÁGUA POTÁVEL</td></tr>
                                                        <tr><td><Button variant='outline' size='xs' radius='xl' compact onClick={handleOnClickIncludeEquipmentCodeButton} data-tag='BCI'>BCI</Button></td><td>BOMBA DE COMBATE A INCENDIO</td></tr>
                                                        <tr><td><Button variant='outline' size='xs' radius='xl' compact onClick={handleOnClickIncludeEquipmentCodeButton} data-tag='BEC'>BEC</Button></td><td>BOMBA DE ESGOTO CLOACAL</td></tr>
                                                        <tr><td><Button variant='outline' size='xs' radius='xl' compact onClick={handleOnClickIncludeEquipmentCodeButton} data-tag='BEP'>BEP</Button></td><td>BOMBA DE ESGOTO PLUVIAL</td></tr>
                                                        <tr><td><Button variant='outline' size='xs' radius='xl' compact onClick={handleOnClickIncludeEquipmentCodeButton} data-tag='BPF'>BPF</Button></td><td>BOMBA COM PRÉ-FILTRO</td></tr>
                                                        <tr><td><Button variant='outline' size='xs' radius='xl' compact onClick={handleOnClickIncludeEquipmentCodeButton} data-tag='EDC'>EDC</Button></td><td>EXAUSTOR DE COLUNA</td></tr>
                                                    </tbody>
                                                </Table>
                                            </HoverCard.Dropdown>
                                        </HoverCard>
                                    }
                                </Group>
                                <Group align='stretch'>
                                    <Radio.Group
                                        orientation="vertical"
                                        label="Tipo"
                                        spacing="sm"
                                        value={inspectionItemEditorValues.type}
                                        onChange={value => setInspectionItemEditorValues({
                                            ...inspectionItemEditorValues,
                                            type: value as TInspectionType
                                        })}
                                    >
                                        <Radio value="EQUIPMENT" label="Equipamento" />
                                        <Radio value="NOTE" label="Anotação" />
                                    </Radio.Group>
                                    <Textarea
                                        label='Descrição'
                                        placeholder='Descrição do item...'
                                        minRows={2}
                                        maxRows={2}
                                        size='sm'
                                        sx={{
                                            flexGrow: 1
                                        }}
                                        value={inspectionItemEditorValues.info}
                                        // onKeyDown={event => {
                                        //     console.log(event.target.selectionStart)
                                        // }}
                                        onChange={event => {
                                            setInspectionItemEditorValues({
                                                ...inspectionItemEditorValues,
                                                info: event.target.value
                                            })
                                        }}
                                    />
                                </Group>
                                <Group position='apart'>
                                    <Button variant='subtle' onClick={() => cancelInspectionItemEditor()} >Cancelar</Button>
                                    <Button variant='outline' onClick={() => saveInspectionItemEditor()}>Salvar item</Button>
                                </Group>
                            </Stack>
                        </Card>
                    </Collapse>
                </Stack>
                <Divider />
                <Group position='apart'>
                    <Button variant='default' leftIcon={<BsX size='1.5em' />}>Cancelar</Button>
                    <Button leftIcon={<BsCheck size='1.5em' />}>Incluir cliente</Button>
                </Group>
            </form>
        </Modal>
    )
}

export default CustomerEditor;