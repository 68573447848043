import React, { useEffect, useState } from 'react';
import { ActionIcon, Badge, Box, Button, Center, createStyles, Group, ScrollArea, Spoiler, Table, Text, TextInput, UnstyledButton } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { keys } from '@mantine/utils';
import { TbChevronDown, TbChevronUp, TbDots, TbGripVertical, TbPencil, TbSearch, TbSelector } from 'react-icons/tb';
import { BsPlus } from 'react-icons/bs';

import { ICustomer } from '../data/ICustomer';
import CustomerEditor from './CustomerEditor';

interface ITableSortProps {
    data: ICustomer[];
}

interface IThProps {
    children: React.ReactNode;
    reversed: boolean;
    sorted: boolean;
    onSort(): void;
}

const defaultCustomer: ICustomer = {
    id: -1,
    name: '',
    address: '',
    contract: 'SIMPLES',
    trustee: [],
    inspection: []
}

const useStyles = createStyles(theme => ({
    th: {
        '&, & *': {
            fontWeight: 'bold',
            color: theme.colors.gray[8]
        },
        '& em, & * em': {
            fontStyle: 'normal',
            fontWeight: 'normal',
        },
        padding: '0!important',
        '&:first-of-type': { width: '7em', },
    },
    control: {
        width: '100%',
        padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0]
        }
    },
    icon: {
        width: 21,
        height: 21,
        borderRadius: 21
    }
}))

function Th({ children, reversed, sorted, onSort }: IThProps) {
    const { classes } = useStyles();
    const Icon = sorted ? (reversed ? TbChevronUp : TbChevronDown) : TbSelector;

    return (
        <th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group position='apart'>
                    <Text weight={500} size='sm'>{children}</Text>
                    <Center className={classes.icon}>
                        <Icon size={14} />
                    </Center>
                </Group>
            </UnstyledButton>
        </th>
    )
}

const allowedSortFields = ['id', 'name', 'contract'];
const allowedFilterFields: string[] = ['name', 'address'];

function filterData(data: ICustomer[], search: string) {
    const query = search.toLocaleLowerCase().trim();
    // const keysOfData = keys(data[0]);
    const keysOfData = allowedFilterFields;
    return data.filter(item =>
        keysOfData
            .some(
                key => (
                    item[key as keyof ICustomer]
                        .toLocaleString()
                        .toLowerCase()
                        .includes(query)
                )
            )
    );
}

function sortData(
    data: ICustomer[],
    payload: {
        sortBy: keyof ICustomer | null;
        reversed: boolean;
        search: string
    }) {
    const { sortBy } = payload;

    if (!sortBy || !allowedSortFields.includes(sortBy))
        return filterData(data, payload.search);

    return filterData(
        [...data].sort((a, b) => {
            switch (sortBy) {
                case 'name':
                case 'contract':
                    if (payload.reversed)
                        return b[sortBy].toLocaleString().localeCompare(a[sortBy].toLocaleString());
                    return a[sortBy].toLocaleString().localeCompare(b[sortBy].toLocaleString());
                case 'id':
                    const numberA: number = a[sortBy] as number;
                    const numberB: number = b[sortBy] as number;
                    if (payload.reversed)
                        return numberB - numberA;
                    return numberA - numberB;
                default:
                    return 0;
            }
        }),
        payload.search
    );
}

export default function CustomersTableSort(
    { data }: ITableSortProps
) {
    const [search, setSearch] = useState('');
    const [sortedData, setSortedData] = useState(data);
    const [sortBy, setSortBy] = useState<keyof ICustomer | null>(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);

    const setSorting = (field: keyof ICustomer) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
        setSortedData(sortData(data, { sortBy: field, reversed, search }));
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        setSearch(value);
        setSortedData(
            sortData(
                data,
                {
                    sortBy,
                    reversed: reverseSortDirection,
                    search: value
                }
            )
        );
    }

    const [customerAddModalOpened, setCustomerAddModalOpened] = useState(false);

    const handleOnClickCustomerAdd = () => {
        setCustomerAddModalOpened(true);
    }

    const rows = sortedData.map(customer => (
        <tr key={customer.id}>
            <td>{customer.id}</td>
            <td>{customer.name}<br />{customer.address}</td>
            <td>{customer.contract}</td>
        </tr>
    ));

    return (
        <Group
            sx={{
                flexDirection: 'column',
                alignItems: 'stretch',
                gap: '1rem',
            }}
        >
            <ScrollArea>
                <TextInput
                    placeholder='Busque pro qualquer campo...'
                    mb='md'
                    icon={<TbSearch size={14} />}
                    value={search}
                    onChange={handleSearchChange}
                />
                <Table
                    horizontalSpacing='md'
                    verticalSpacing='xs'
                    highlightOnHover
                    striped
                    sx={{
                        tableLayout: 'fixed',
                        minWidth: 700,
                    }}
                >
                    <thead>
                        <tr>
                            <Th
                                sorted={sortBy === 'id'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('id')}
                            >
                                ID
                            </Th>
                            <Th
                                sorted={sortBy === 'name'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('name')}
                            >
                                Nome <em>e Endereço</em>
                            </Th>
                            <Th
                                sorted={sortBy === 'contract'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('contract')}
                            >
                                Contrato
                            </Th>
                        </tr>
                    </thead>
                    <tbody>{rows.length > 0 && rows}</tbody>
                </Table>
                {rows.length <= 0 &&
                    <Group align='center' position='center'>
                        <Text size='md' align='center' style={{ padding: '1rem' }}>
                            Lista sem clientes.
                        </Text>
                    </Group>
                }
            </ScrollArea>
            <CustomerEditor customer={defaultCustomer} opened={customerAddModalOpened} setOpened={setCustomerAddModalOpened} />
            <Group position='right'>
                <Button
                    variant='outline'
                    leftIcon={<BsPlus size='2em' />}
                    onClick={handleOnClickCustomerAdd}
                >
                    Incluir Cliente
                </Button>
            </Group>
        </Group>

    );
}
