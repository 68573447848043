// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import App from './App';
// // import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );


import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { MantineProvider, ColorSchemeProvider, ColorScheme, MantineTheme } from '@mantine/core'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useHotkeys, useLocalStorage } from '@mantine/hooks'

import App from './App'
import HomePage from './routes/HomePage'
import DocCreatePage from './routes/DocCreatePage'
import DocReportPage from './routes/DocReportPage'
import DocUpdatePage from './routes/DocUpdatePage'
import NoPage from './routes/NoPage'


const ReactDOMRoot = ReactDOM
  .createRoot(
    document.getElementById('root') as HTMLElement
  )

function Root() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    defaultValue: 'light',
    key: 'mantine-color-scheme',
    getInitialValueInEffect: true
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  useHotkeys([['mod+*', () => toggleColorScheme()]]);

  return (
    <React.StrictMode>
      <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
        <MantineProvider
          theme={{
            colorScheme,
            fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, \'Segoe UI\', Oxygen, Ubuntu, Cantarell, \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', sans-serif',
            fontFamilyMonospace: 'Azeret Mono, source-code-pro, Menlo, Monaco, Consolas, \'Courier New\', monospace',
            headings: {
              fontFamily: 'Quicksand, -apple-system, BlinkMacSystemFont, \'Segoe UI\', Oxygen, Ubuntu, Cantarell, \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', sans-serif',
              fontWeight: 'normal'
            },
            other: {
              shadows: {
                xs: '0px 3px  1px  -2px rgba(0, 0, 0, 0.2), 0px 2px  2px  0px rgba(0, 0, 0, 0.14), 0px 1px 5px  0px rgba(0, 0, 0, 0.12)',
                sm: '0px 3px  5px  -1px rgba(0, 0, 0, 0.2), 0px 6px  10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
                md: '0px 7px  8px  -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
                lg: '0px 9px  11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
                xl: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
              },
            },
            components: {
              Button: {
                styles: (theme) => ({
                  label: {
                    fontFamily: theme.headings.fontFamily,
                  }
                })
              },
            }
          }}
          withGlobalStyles
          withNormalizeCSS
        >
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<App />}>
                <Route index element={<HomePage />} />
                <Route path='doc' element={<DocReportPage />} />
                <Route path='doc/create' element={<DocCreatePage />} />
                <Route path='doc/update' element={<DocUpdatePage />} />
                <Route path='*' element={<NoPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </MantineProvider>
      </ColorSchemeProvider>
    </React.StrictMode>
  );
}

ReactDOMRoot.render(<Root />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();