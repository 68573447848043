import React, { useRef, useState } from 'react'
import { AppShell, Group, Header, Title } from '@mantine/core'
import BrandLogo from './components/Brand'
import ToggleThemeButton from './components/ToggleThemeButton'
import { Outlet, useNavigate } from 'react-router-dom'

function App() {
  const navigate = useNavigate()

  return (
    <AppShell
      styles={(theme) => ({
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
        },
      })}
      padding='md'
      header={
        <Header
          height={64}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '1rem',
          }}
        >
          <Group onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
            <BrandLogo size={32} />
            <Title order={1} sx={{ fontSize: '1.5rem' }}>Relatório de Inspeção</Title>
          </Group>
          <ToggleThemeButton />
        </Header>
      }
    >
      <Outlet />
    </AppShell>
  )
}

export default App
