import { Button, Card, CardSection, Center, Container, createStyles, Grid, Group, Text } from '@mantine/core'
import { Link } from 'react-router-dom'
import { BsFileEarmarkPlus, BsFileEarmarkArrowUp } from 'react-icons/bs'
import { shadows } from '../conf/ThemeConf'

const useStyles = createStyles((theme) => ({
    option: {
        maxWidth: 240,
        ...shadows.z06,
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'center',
    },
    optionLabel: {
        flexGrow: 1,
        display: 'flex',
        borde: '1px dashed red',
    },
    optionImg: {
        opacity: 0.5,
    },
    optionLink: {
        textDecoration: 'none',
        display: 'flex',
    }
}))

export default function HomePage() {
    const { classes } = useStyles()

    return (
        <Group align='stretch' position='center'>
            <Link
                to='/doc/create'
                className={classes.optionLink}
            >
                <Card
                    withBorder
                    className={classes.option}
                >
                    <BsFileEarmarkPlus
                        size={96}
                        className={classes.optionImg}
                    />
                    <Text
                        className={classes.optionLabel}
                    >
                        Criar nova coleção de formulários vazia.
                    </Text>
                    <Button
                        fullWidth
                        size='lg'
                        component='button'
                    >
                        Criar Formulários
                    </Button>
                </Card>
            </Link>
            <Link to='/doc/update'
                className={classes.optionLink}
            >
                <Card
                    shadow='lg'
                    withBorder
                    className={classes.option}
                >
                    <BsFileEarmarkArrowUp
                        size={96}
                        className={classes.optionImg}
                    />
                    <Text
                        className={classes.optionLabel}
                    >
                        Abrir um arquivo com uma coleção de formulários já existentes.
                    </Text>
                    <Button
                        fullWidth size='lg'
                    >
                        Abrir Formulários
                    </Button>
                </Card>
            </Link>
        </Group>
    )
}