import React from 'react'
import { ActionIcon, useMantineColorScheme } from '@mantine/core';
import { BsSun, BsMoonStars } from 'react-icons/bs';

export default function ToggleThemeButton() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === 'dark';

  return (
    <ActionIcon
      variant="outline"
    //   color={dark ? 'gray[9]' : 'gray[3]'}
      onClick={() => toggleColorScheme()}
      title="Toggle color scheme"
    >
      {dark ? <BsSun size={18} /> : <BsMoonStars size={18} />}
    </ActionIcon>
  );
}