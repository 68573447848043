import { Button, Card, Container, Group, NumberInput, Paper, SimpleGrid, Textarea, TextInput, Title } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import 'dayjs/locale/pt-br'
import { useForm } from '@mantine/form'
import { BsCalendar2Date } from 'react-icons/bs'

import BrandDropzone from '../components/BrandDropzone'
import CustomersEditor from '../components/CustomersEditor'
import CustomersTableSort from '../components/CustomersTableSort'
import { ICustomer } from '../data/ICustomer'

// import * as Chance from 'chance';
import Chance from 'chance';
// const chance = new Chance() as Chance.Chance
const chance = new Chance() as Chance.Chance

export default function DocCreatePage() {

    const reportDateNow = (new Date()).toLocaleDateString()

    const form = useForm({
        initialValues: {
            company: {
                logo: '',
                name: '',
                address: '',
                contact: '',
            },
            report: {
                number: '',
                date: reportDateNow,
            },
            customers: []
        },
    })

    const onChangeCompanyLogo = (imageBase64: string) => {
        form.setFieldValue('company.logo', imageBase64)
    }


    const data: ICustomer[] = [];
    for (let i = 0; i < 25; i++) data.push({
        id: i,
        name: chance.name(),
        address: chance.address(),
        trustee: [],
        contract: (Math.random() > 0.5 ? 'GLOBAL' : 'SIMPLES'),
        inspection: []
    })

    return (
        <Container
            style={{
                display: 'flex',
                gap: '1rem',
                flexDirection: 'column',
            }}>
            <Title order={2}>Criar Formulários</Title>
            <form
                onSubmit={form.onSubmit(values => console.log(values))}
                style={{
                    display: 'flex',
                    gap: '1rem',
                    flexDirection: 'column',
                }}
            >
                <Card shadow='sm' p='md'>
                    <Group
                        sx={{
                            alignItems: 'stretch'
                        }}
                    >
                        <BrandDropzone
                            sx={{
                                maxWidth: 240,
                                flexGrow: 1
                            }}
                            handleOnChangeFile={onChangeCompanyLogo}
                        />
                        <Group
                            sx={{
                                flexGrow: 1,
                                alignItems: 'stretch',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}
                        >
                            <TextInput
                                required
                                label='Nome da empresa'
                                placeholder='Nome Fantasia ou Razão Social'
                                {...form.getInputProps('company.name')}
                            />
                            <SimpleGrid
                                cols={2}
                            >
                                <Textarea
                                    required
                                    label='Endereço'
                                    placeholder='Endereço da empresa...'
                                    minRows={3}
                                    {...form.getInputProps('company.address')}
                                />
                                <Textarea
                                    required
                                    label='Contato'
                                    placeholder='Telefone, e-mail, meios de contato com a empresa...'
                                    minRows={3}
                                    {...form.getInputProps('company.contact')}
                                />
                            </SimpleGrid>
                            <SimpleGrid
                                cols={2}
                            >
                                <NumberInput
                                    label='Número do relatório'
                                    placeholder='Número do relatório'
                                    required
                                    size='md'
                                    {...form.getInputProps('report.number')}
                                />
                                <DatePicker
                                    label='Data do relatório'
                                    locale='pt-br'
                                    placeholder={reportDateNow}
                                    firstDayOfWeek='sunday'
                                    inputFormat='MM/DD/YYYY'
                                    defaultValue={reportDateNow}
                                    size='md'
                                    icon={<BsCalendar2Date size={24} />}
                                    required
                                    onChange={value => form.setFieldValue('report.date', value?.toLocaleDateString())}
                                    {...form.getInputProps('report.date')}
                                />
                            </SimpleGrid>
                        </Group>
                    </Group>
                </Card>
                <Card shadow='sm'
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem'
                    }}
                >
                    <Title order={3}>Clientes</Title>
                    {/* <CustomersEditor/> */}
                    <CustomersTableSort data={data} />
                </Card>
                <Group position='right' mt='md'>
                    <Button type='submit'>Salvar</Button>
                </Group>
            </form>
        </Container>
    )
}