// import { createStyles } from '@mantine/core'

// const useStyles = createStyles(() => ({
//     shadowZ00: { boxShadow: '0px 0px  0px  0px  rgba(0, 0, 0, 0.2), 0px 0px  0px  0px rgba(0, 0, 0, 0.14), 0px 0px 0px  0px rgba(0, 0, 0, 0.12)' },
//     shadowZ01: { boxShadow: '0px 2px  1px  -1px rgba(0, 0, 0, 0.2), 0px 1px  1px  0px rgba(0, 0, 0, 0.14), 0px 1px 3px  0px rgba(0, 0, 0, 0.12)' },
//     shadowZ02: { boxShadow: '0px 3px  1px  -2px rgba(0, 0, 0, 0.2), 0px 2px  2px  0px rgba(0, 0, 0, 0.14), 0px 1px 5px  0px rgba(0, 0, 0, 0.12)' },
//     shadowZ03: { boxShadow: '0px 3px  3px  -2px rgba(0, 0, 0, 0.2), 0px 3px  4px  0px rgba(0, 0, 0, 0.14), 0px 1px 8px  0px rgba(0, 0, 0, 0.12)' },
//     shadowZ04: { boxShadow: '0px 2px  4px  -1px rgba(0, 0, 0, 0.2), 0px 4px  5px  0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)' },
//     shadowZ05: { boxShadow: '0px 3px  5px  -1px rgba(0, 0, 0, 0.2), 0px 5px  8px  0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)' },
//     shadowZ06: { boxShadow: '0px 3px  5px  -1px rgba(0, 0, 0, 0.2), 0px 6px  10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)' },
//     shadowZ07: { boxShadow: '0px 4px  5px  -2px rgba(0, 0, 0, 0.2), 0px 7px  10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)' },
//     shadowZ08: { boxShadow: '0px 5px  5px  -3px rgba(0, 0, 0, 0.2), 0px 8px  10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)' },
//     shadowZ09: { boxShadow: '0px 5px  6px  -3px rgba(0, 0, 0, 0.2), 0px 9px  12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)' },
//     shadowZ10: { boxShadow: '0px 6px  6px  -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)' },
//     shadowZ11: { boxShadow: '0px 6px  7px  -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12)' },
//     shadowZ12: { boxShadow: '0px 7px  8px  -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)' },
//     shadowZ13: { boxShadow: '0px 7px  8px  -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)' },
//     shadowZ14: { boxShadow: '0px 7px  9px  -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12)' },
//     shadowZ15: { boxShadow: '0px 8px  9px  -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12)' },
//     shadowZ16: { boxShadow: '0px 8px  10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)' },
//     shadowZ17: { boxShadow: '0px 8px  11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12)' },
//     shadowZ18: { boxShadow: '0px 9px  11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)' },
//     shadowZ19: { boxShadow: '0px 9px  12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12)' },
//     shadowZ20: { boxShadow: '0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12)' },
//     shadowZ21: { boxShadow: '0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12)' },
//     shadowZ22: { boxShadow: '0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12)' },
//     shadowZ23: { boxShadow: '0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12)' },
//     shadowZ24: { boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)' },
// }))

// export default useStyles

export const shadows = {
    z00: { boxShadow: '0px 0px  0px  0px  rgba(0, 0, 0, 0.2), 0px 0px  0px  0px rgba(0, 0, 0, 0.14), 0px 0px 0px  0px rgba(0, 0, 0, 0.12)' },
    z01: { boxShadow: '0px 2px  1px  -1px rgba(0, 0, 0, 0.2), 0px 1px  1px  0px rgba(0, 0, 0, 0.14), 0px 1px 3px  0px rgba(0, 0, 0, 0.12)' },
    z02: { boxShadow: '0px 3px  1px  -2px rgba(0, 0, 0, 0.2), 0px 2px  2px  0px rgba(0, 0, 0, 0.14), 0px 1px 5px  0px rgba(0, 0, 0, 0.12)' },
    z03: { boxShadow: '0px 3px  3px  -2px rgba(0, 0, 0, 0.2), 0px 3px  4px  0px rgba(0, 0, 0, 0.14), 0px 1px 8px  0px rgba(0, 0, 0, 0.12)' },
    z04: { boxShadow: '0px 2px  4px  -1px rgba(0, 0, 0, 0.2), 0px 4px  5px  0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)' },
    z05: { boxShadow: '0px 3px  5px  -1px rgba(0, 0, 0, 0.2), 0px 5px  8px  0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)' },
    z06: { boxShadow: '0px 3px  5px  -1px rgba(0, 0, 0, 0.2), 0px 6px  10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)' },
    z07: { boxShadow: '0px 4px  5px  -2px rgba(0, 0, 0, 0.2), 0px 7px  10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)' },
    z08: { boxShadow: '0px 5px  5px  -3px rgba(0, 0, 0, 0.2), 0px 8px  10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)' },
    z09: { boxShadow: '0px 5px  6px  -3px rgba(0, 0, 0, 0.2), 0px 9px  12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)' },
    z10: { boxShadow: '0px 6px  6px  -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)' },
    z11: { boxShadow: '0px 6px  7px  -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12)' },
    z12: { boxShadow: '0px 7px  8px  -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)' },
    z13: { boxShadow: '0px 7px  8px  -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)' },
    z14: { boxShadow: '0px 7px  9px  -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12)' },
    z15: { boxShadow: '0px 8px  9px  -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12)' },
    z16: { boxShadow: '0px 8px  10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)' },
    z17: { boxShadow: '0px 8px  11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12)' },
    z18: { boxShadow: '0px 9px  11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)' },
    z19: { boxShadow: '0px 9px  12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12)' },
    z20: { boxShadow: '0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12)' },
    z21: { boxShadow: '0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12)' },
    z22: { boxShadow: '0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12)' },
    z23: { boxShadow: '0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12)' },
    z24: { boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)' },
}