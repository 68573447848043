import { Button, FileButton, Group, Image, Text, useMantineTheme } from '@mantine/core';
import { TbUpload, TbPhoto, TbX } from 'react-icons/tb';
import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useEffect, useState } from 'react';

type TBrandProps = {
  handleOnChangeFile: (s: any) => void
}

type TBrandDropzoneProps = TBrandProps & DropzoneProps

const BrandDropzone = (props: Partial<TBrandDropzoneProps>) => {
  const theme = useMantineTheme();
  const [file, setFile] = useState<File | null>(null)
  const [imageUrl, setImageUrl] = useState<string>('')
  const { handleOnChangeFile, ...dropzoneProps } = props

  const onDropFile = (files: File[] | null) => {
    if (files && Array.isArray(files) && files.length > 0)
      setFile(files[0])
  }

  useEffect(() => {
    let imageFileReader: FileReader
    if (file && ('webkitRelativePath' in file)) {
      setImageUrl(URL.createObjectURL(file))

      imageFileReader = new FileReader()
      imageFileReader.readAsDataURL(file)
      imageFileReader.onload = (event) => {
        handleOnChangeFile?.(event.target?.result)
      }
    }
  }, [file])


  return (
    <Group
      sx={{
        flexDirection: 'column',
        gap: '0.25rem',
        alignItems: 'flex-start'
      }}
    >
      <Text size="sm">
        Imagem da marca da empresa
      </Text>
      <Dropzone
        onDrop={onDropFile}
        maxSize={3 * 1024 ** 2}
        maxFiles={1}
        multiple={false}
        accept={IMAGE_MIME_TYPE}
        {...dropzoneProps}
      >
        <Group
          position="center"
          spacing="sm"
          // style={{ minHeight: 220 }}
        >
          <Dropzone.Accept>
            <TbUpload
              size={50}
              strokeWidth={1.5}
              color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <TbX
              size={50}
              strokeWidth={1.5}
              color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            {file &&
              <Image
                src={imageUrl}
                imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
                width={220}
                height={96}
                fit='contain'
              />
            }
            {!file &&
              <TbPhoto size={50} strokeWidth={1.5} />
            }
          </Dropzone.Idle>

            <Text size="xs">
              Arraste e solte a imagem do logo aqui ou clique para selecionar o arquivo de imagem.
              O tamanho do arquivo deve ser menor que 5MB e no máximo 220px de largura por 96px de altura.
            </Text>
        </Group>
      </Dropzone>
      <FileButton
        onChange={setFile}
        accept="image/*"
      >
        {props => (
          <Button
            fullWidth
            variant='default'
            {...props}
          >
            Selecionar imagem
          </Button>
        )}
      </FileButton>
    </Group>
  );
}

export default BrandDropzone